import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import Soleil from '../../img/soleil.png'
import Froid from '../../img/froid.jpg'

const Meteo = () => {

  const [temp, settemp] = useState([]);
  const ville = "Montpellier"

  useEffect(() => {
    axios(`https://api.openweathermap.org/data/2.5/weather?q=${ville}&appid=b391fd1e50ca575d8a12938c64fca49e`).then((res) => {
      settemp(res.data.main);
      
    });
  }, []);


  return (
    <Fragment>
        <div className="meteo">
          {(Math.round(temp.temp - 273.15) <= 10 ? <div style={{padding:"15px 15px 15px 35px"}}><img className="soleil" src={Froid} alt="" /> {Math.round(temp.temp - 273.15)}° C à {ville} : Remember to cover yourself</div> : "")}
                        
        </div>
    </Fragment>
  );
};

export default Meteo;